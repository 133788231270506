import { Button, Modal, Typography } from "antd";
import "./index.css"; // Ensure this file includes your styles
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as Discord } from "assets/svg/whiteDiscord.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { connectDiscord } from "state/auth/actions";
import { Empty } from "antd";

import React from "react";

interface ConnectModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTask?: any;
  setSelectedTask?: any;
  IsDiscordConnected?: boolean;
}

const parseContent = (data: any) => {
  if (data && data.content && Array.isArray(data.content)) {
    return data.content.flatMap((item: any) => {
      if (item.type === "paragraph") {
        return item.content && Array.isArray(item.content)
          ? item.content.flatMap((textNode: any) => {
              if (textNode.type === "text") {
                const text = textNode.text || "";
                const marks = textNode.marks || [];
                return marks.reduce((acc: any, mark: any) => {
                  const Tag =
                    mark.type === "link"
                      ? "a"
                      : mark.type === "italic"
                        ? "em"
                        : mark.type === "bold"
                          ? "strong"
                          : mark.type === "underline"
                            ? "u"
                            : mark.type === "code"
                              ? "code"
                              : null;

                  if (Tag) {
                    return React.createElement(Tag, {
                      key: Math.random(),
                      ...(mark.type === "link"
                        ? {
                            href: mark.attrs.href,
                            target: mark.attrs.target,
                            rel: mark.attrs.rel
                          }
                        : {}),
                      children: acc
                    });
                  }
                  return acc;
                }, text);
              } else if (textNode.type === "hardBreak") {
                return <br key={Math.random()} />;
              }
              return null; // Return null for unhandled types
            })
          : [""]; // Handle empty paragraphs
      } else if (item.type === "heading") {
        const level = item.attrs?.level || 1;
        const headingContent =
          item.content && Array.isArray(item.content)
            ? item.content.map((textNode: any) => textNode.text || "").join("")
            : ""; // Ensure content is defined
        return React.createElement(`h${level}`, { key: Math.random() }, headingContent);
      } else if (item.type === "orderedList" && item.content && Array.isArray(item.content)) {
        const orderedListItems = item.content.map((listItem: any) => {
          const listItemContent =
            listItem.content && Array.isArray(listItem.content)
              ? listItem.content
                  .flatMap((paragraph: any) => {
                    return paragraph.content && Array.isArray(paragraph.content)
                      ? paragraph.content.map((textNode: any) => textNode.text || "").join("")
                      : ""; // Handle empty content
                  })
                  .join("")
              : "";
          return <li key={Math.random()}>{listItemContent}</li>;
        });
        return <ol key={Math.random()}>{orderedListItems}</ol>;
      } else if (item.type === "bulletList" && item.content && Array.isArray(item.content)) {
        const bulletListItems = item.content.map((listItem: any) => {
          const listItemContent =
            listItem.content && Array.isArray(listItem.content)
              ? listItem.content
                  .flatMap((paragraph: any) => {
                    return paragraph.content && Array.isArray(paragraph.content)
                      ? paragraph.content.map((textNode: any) => textNode.text || "").join("")
                      : ""; // Handle empty content
                  })
                  .join("")
              : "";
          return <li key={Math.random()}>{listItemContent}</li>;
        });
        return <ul key={Math.random()}>{bulletListItems}</ul>;
      } else if (item.type === "youtube") {
        return (
          <iframe
            key={Math.random()}
            width={item.attrs.width}
            height={item.attrs.height}
            src={item.attrs.src}
            frameBorder="0"
            allowFullScreen
          />
        );
      } else if (item.type === "codeBlock") {
        const codeContent =
          item.content && Array.isArray(item.content)
            ? item.content.map((textNode: any) => textNode.text || "").join("")
            : ""; // Handle empty content
        return (
          <pre key={Math.random()}>
            <code>{codeContent}</code>
          </pre>
        );
      } else if (item.type === "image") {
        return <img key={Math.random()} src={item.attrs.src} alt={item.attrs.alt || ""} />;
      }
      return null; // Return null for unhandled types
    });
  }
  return []; // Return empty array if data is not valid
};

const CommonModal: React.FC<ConnectModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  selectedTask,
  setSelectedTask,
  IsDiscordConnected = false // Default to false
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { scoreData } = useSelector((state: RootState) => state.profile);

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedTask && setSelectedTask(null);
  };

  const handleConnectDiscord = () => {
    dispatch(connectDiscord());
  };

  console.log("Sele", selectedTask);
  const parsedText = parseContent(selectedTask?.description);
  console.log("KKK", parsedText);
  const redirectToTask = (id: any) => {
    console.debug("ID", id);
    window.open(`${id}`, "_blank");
  };

  function getStatus(selectedTask: any) {
    if (!selectedTask) return ""; // Handle case where selectedTask is undefined

    if ("progress" in selectedTask) {
      // Check progress status
      return selectedTask.progress === "In Progress" ? "In Progress" : "Completed";
    } else if ("status" in selectedTask) {
      // Check status values
      if (selectedTask.status === "pending") {
        return "In Progress";
      } else if (selectedTask.status === "success") {
        return "Completed";
      }
      // For 'not_started' and 'fail', return empty string
      return "";
    }

    return ""; // Return empty if neither key is present
  }
  console.log("DDD", selectedTask);

  return (
    <Modal open={isModalOpen} footer={null} onCancel={onModalClose} closable={false} width={800}>
      <div className="modal_body_task">
        <div className="modal_header">
          <div className="modal_title">
            {selectedTask?.taskId?.taskName || selectedTask?.name} <span>{getStatus(selectedTask)}</span>
          </div>
          <div className="modal_close">
            <div className="mento_tag">MentoScholar-{scoreData?.multiplier}x</div>
            <CloseOutlined className="close_icon" onClick={onModalClose} />
          </div>
        </div>

        <div className="modal_participate" style={{ display: "flex", gap: 10, margin: "10px 0px" }}>
          <div className="rewards_title">Rewards:</div>
          <div className="mscore_tag">MScore: +{selectedTask?.taskId?.mScore || selectedTask?.rewards[0]?.value}</div>
        </div>

        <div className="modal_participate">
          <div className="participate_title">Description:</div>
          <div className="participate_list">
            <div className="step_description">{selectedTask?.taskId?.taskDescription}</div>
          </div>
        </div>
        <div>
          {parsedText.map((item: any, index: any) => (
            <div key={index} style={{ fontFamily: "Founders Grotesk", fontWeight: 400 }}>
              {item}
            </div>
          ))}
        </div>

        <div>
          <ul>
            {selectedTask?.tasks && selectedTask.tasks.length > 0 ? (
              (() => {
                const titles = selectedTask.tasks
                  .filter((item: any) => item?.settings?.title)
                  .map((item: any) => item.settings.title);

                if (titles.length > 0) {
                  return titles.map((title: any, index: any) => (
                    <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                      <li style={{ fontFamily: "Founders Grotesk", fontWeight: 400 }}>{title}</li>
                    </div>
                  ));
                } else {
                  return (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<Typography.Text>No titles available</Typography.Text>}
                    />
                  );
                }
              })()
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<Typography.Text>No Subtasks available</Typography.Text>}
              />
            )}
          </ul>
        </div>

        {!IsDiscordConnected ? (
          <div style={{ marginTop: 60 }}>
            <div className="button_gradient_wrapper">
              <div
                style={{
                  height: 46,
                  position: "absolute",
                  borderRadius: 8,
                  top: "-30px",
                  background: "#5865F2",
                  paddingTop: 5,
                  width: 132,
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  padding: 9
                }}
              >
                {" "}
                <Discord className="discord-icon" width={16} height={12} style={{ marginTop: 3 }} />{" "}
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Founders Grotesk",
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "flex-start"
                  }}
                >
                  {" "}
                  Connect Discord First
                </span>
              </div>
              <div className="transparent_div">
                <Button type="primary" onClick={() => handleConnectDiscord()} className="special_button">
                  <div className="discord-button-content">
                    <Discord className="discord-icon" width={24} height={18} />
                    <div style={{ fontSize: 18, fontFamily: "Founders Grotesk" }}> Connect Discord</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="button_margin">
            <Button
              shape="round"
              type="primary"
              style={{ borderRadius: 12 }}
              className="button_primary_mdal"
              onClick={() => redirectToTask(selectedTask?.redirectUrl)}
            >
              Perform Task &#8594;
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CommonModal;
