import { useState, useEffect } from 'react';

export const useLocalStorageListener = (key:any) => {
  const [value, setValue] = useState(() => localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = (event:any) => {
      if (event.key === key) {
        setValue(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  return value;
};
