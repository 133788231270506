import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TradersPortTaskState {
  tradersPortTask: [];
  tradersPortTaskLoading: boolean;
  tradersPortTaskError: boolean | null | string;

  socialsPortTask: [];
  socialsPortTaskLoading: boolean;
  socialsPortTaskError: boolean | null | string;
}

const initialState: TradersPortTaskState = {
  tradersPortTask: [],
  tradersPortTaskLoading: false,
  tradersPortTaskError: null,

  socialsPortTask: [],
  socialsPortTaskLoading: false,
  socialsPortTaskError: null
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    traderPortTaskLoading(state) {
      state.tradersPortTaskLoading = true;
      state.tradersPortTaskError = null;
    },
    traderPortTaskSuccess(state, action: any) {
      state.tradersPortTaskLoading = false;
      state.tradersPortTask = action.payload;
    },
    traderPortTaskFailure(state, action: PayloadAction<string>) {
      state.tradersPortTaskLoading = false;
      state.tradersPortTaskError = action.payload;
    },


    socialsPortTaskLoading(state) {
        state.socialsPortTaskLoading = true;
        state.socialsPortTaskError = null;
      },
      socialsPortTaskSuccess(state, action: any) {
        state.socialsPortTaskLoading = false;
        state.socialsPortTask = action.payload;
      },
      socialsPortTaskFailure(state, action: PayloadAction<string>) {
        state.socialsPortTaskLoading = false;
        state.socialsPortTaskError = action.payload;
      }
  }
});

export const { traderPortTaskLoading, traderPortTaskSuccess, traderPortTaskFailure, socialsPortTaskLoading,socialsPortTaskSuccess, socialsPortTaskFailure } = taskSlice.actions;

export default taskSlice.reducer;
