import React from "react";
import { Table } from "antd";
import { useWindowSize } from "hooks";
import { Styles } from "types";
import Rank3 from "assets/images/Rank3.png";
import Rank1 from "assets/images/Rank1.png";
import Rank2 from "assets/images/Rank2.png";
// import Profile from "assets/images/Profile.png";
import { ReactComponent as Crown } from "assets/svg/Crown.svg";
import "./LeaderBoard.css";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

const LeaderBoard: React.FC = () => {
  const { isMobile, isTablet } = useWindowSize();
  const { scoreData } = useSelector((state: RootState) => state.profile);
  const { leaderBoardData } = useSelector((state: RootState) => state.profile);
  const { isLogin } = useSelector((state: RootState) => state.auth);

  const sortedLeaderboardData = leaderBoardData
    ? [...leaderBoardData].sort((a: any, b: any) => b.points - a.points)
    : [];

  // console.log("KKK", leaderBoardData, sortedLeaderboardData);

  const styles: Styles = {
    tableContainer: {
      overflowX: "auto",
      overflowY: "auto"
    },
    header: {
      fontWeight: "bold",
      textAlign: "center"
    },
    userImage: {
      position: "relative",
      height: 66,
      width: 66,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      margin: "0 auto"
    },
    userName: {
      marginTop: "8px",
      marginBottom: 0,
      fontSize: "12px",
      color: "#000000",
      fontWeight: "500",
      fontFamily: "Founders Grotesk"
    }
  } as const;
  const columns = [
    {
      title: <div className="header position-header">Position</div>,
      dataIndex: "position",
      key: "position",
      render: (text: string) => <div className="position-column">{text}</div>,
      className: "position-column",
      width: 40
    },
    {
      title: <div className="header name-header">Name</div>,
      dataIndex: "name",
      key: "name",
      render: (text: string) => <div className="name-column">{text}</div>,
      className: "name-column",
      width: 230
    },
    {
      title: <div className="header date-header">Discord Roles</div>,
      dataIndex: "discordRole",
      key: "discordRole",
      render: (text: string) => <div className="date-column">{text}</div>,
      className: "date-column",
      width: 140
    },
    {
      title: <div className="header points-header">Points Earned</div>,
      dataIndex: "points",
      key: "points",
      render: (text: string) => <div className="points-column">+{text}</div>,
      className: "points-column",
      width: isMobile || isTablet ? "auto" : 160
    }
  ];
  return (
    <div className="leaderboard_container">
      <div className="leaderboard_child">
        <div className="leaderboard_wrapper_header">
          <div className="leaderboard_heading">LeaderBoard</div>
          {isLogin && (
            <div className="relative_div">
              <div className="relative_div_number">#{scoreData?.rank}</div>
              <div className="better_div"> You are doing 60% better than other players</div>
            </div>
          )}
        </div>
        <div className="parent_table_container">
          <div className="parent_content_award">
            <div className="award_background" />

            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 5 }}>
              <div style={{ position: "relative", top: 75, left: 20 }}>
                <div className="rank_one_user">
                  <div style={{ ...styles.userImage, backgroundImage: `url(${sortedLeaderboardData[2]?.image})` }} />
                  <p style={styles.userName}>{sortedLeaderboardData[2]?.name}</p>
                </div>

                <img src={Rank3} className="rank_three_image" />
              </div>

              <div style={{ zIndex: 2 }}>
                <Crown className="crown" />
                <div className="rank_two_user">
                  <div style={{ ...styles.userImage, backgroundImage: `url(${sortedLeaderboardData[0]?.image})` }} />
                  <p style={styles.userName}>{sortedLeaderboardData[0]?.name}</p>
                </div>
                <img src={Rank1} className="rank_one_image" />
              </div>

              <div style={{ position: "relative", top: 50, left: -20 }}>
                <div className="rank_three_user">
                  <div style={{ ...styles.userImage, backgroundImage: `url(${sortedLeaderboardData[1]?.image})` }} />
                  <p style={styles.userName}>{sortedLeaderboardData[1]?.name}</p>
                </div>

                <img src={Rank2} className="rank_two_image" />
              </div>
            </div>
          </div>

          <div className="table-container" style={styles.tableContainer}>
            <Table columns={columns} dataSource={leaderBoardData} pagination={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
