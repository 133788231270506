import React, { useCallback, useEffect, useState } from "react";

import { useWeb3React } from "@web3-react/core";
import { Button } from "antd";

import { metaMask } from "connectors/metaMask";
import { walletConnect } from "connectors/walletConnect";
import { useWindowSize } from "hooks";
import { getEllipsisTxt } from "utils/formatters";

import ConnectModal from "./ConnectModal";
import DisconnectModal from "./DisconnectModal";
import Jazzicons from "../Jazzicons";
import Purse from "assets/images/Purse1.png";
import "./index.css";
import { setIsLogin } from "state/auth/reducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";

interface WantedChain {
  chain?: number;
}

const ConnectAccount: React.FC<WantedChain> = () => {
  const { account } = useWeb3React();
  const { isTablet } = useWindowSize();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const disconnect = useCallback(async () => {
    const connector = metaMask || walletConnect;
    setIsModalVisible(false);
    setIsAuthModalOpen(false);
    localStorage.removeItem("connectorId");
    localStorage.removeItem("token");
    localStorage.removeItem("id");

    if (connector.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    // @ts-expect-error close can be returned by wallet
    if (connector?.close) {
      // @ts-expect-error close can be returned by wallet
      await connector.close();
    }
    dispatch(setIsLogin(false));
  }, []);

  useEffect(() => {
    const connectWallet = async () => {
      const connectorId = localStorage.getItem("connectorId");
      if (connectorId && connectorId === "MetaMask") {
        await metaMask?.activate();
      } else {
        if (connectorId && connectorId === "WalletConnect") await walletConnect?.activate();
      }
    };

    connectWallet().catch((error) => {
      console.error("Failed to connect wallet:", error);
    });
  }, []);

  return (
    <>
      {account === undefined ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            shape="round"
            type="primary"
            className="button_primary_custom"
            onClick={() => setIsAuthModalOpen(true)}
          >
            <img src={Purse} className="purse_icon" alt="Purse Icon" />
            Connect Wallet
          </Button>

          <ConnectModal isModalOpen={isAuthModalOpen} setIsModalOpen={setIsAuthModalOpen} />
          <br />
        </div>
      ) : (
        <>
          <Button className="account" onClick={() => setIsModalVisible(true)}>
            <Jazzicons seed={account} />

            {account && typeof account === "string" && (
              <p className="address_button">{getEllipsisTxt(account, isTablet ? 3 : 6)}</p>
            )}
          </Button>

          <DisconnectModal isModalOpen={isModalVisible} setIsModalOpen={setIsModalVisible} disconnect={disconnect} />
        </>
      )}
    </>
  );
};

export default ConnectAccount;
