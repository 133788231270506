import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'interceptor/axiosInstance';
import { notifyError } from 'uiComponents/Toast';
import { socialsPortTaskFailure, socialsPortTaskLoading, socialsPortTaskSuccess, traderPortTaskFailure, traderPortTaskLoading, traderPortTaskSuccess,  } from './reducer';




export const _tradersPortTask = createAsyncThunk(
    'tasks/tradersPortTask',
    async (walletAddress: string|undefined, { dispatch }) => {
      dispatch(traderPortTaskLoading());
      try {
       const response =  await axiosInstance.get(`tasks/user/${walletAddress}` );
       dispatch(traderPortTaskSuccess(response?.data?.data?.tasks));

      } catch (error: any) {
        notifyError('Error')
        dispatch(traderPortTaskFailure(error.message || "Error submitting referral"));
        throw error; 
      }
    }
  );
  
  export const _socialsPortTask = createAsyncThunk(
    'tasks/socialsPortTask',
    async ( _userId: string|undefined, { dispatch }) => {
      dispatch(socialsPortTaskLoading());
      try {
       const response =  await axiosInstance.get(`zealy/quests?userId=${_userId}` );
       dispatch(socialsPortTaskSuccess(response?.data?.data));

      } catch (error: any) {
        notifyError('Error')
        dispatch(socialsPortTaskFailure(error.message || "Error submitting referral"));
        throw error; 
      }
    }
  );
  