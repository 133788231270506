import { Button, Input, Modal } from "antd";
import "./index.css";

import React, { useState } from "react";
import { submitReferral } from "state/auth/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { CloseOutlined } from "@ant-design/icons";
import { notifySuccess } from "uiComponents/Toast";

interface ConnectModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReferralModal: React.FC<ConnectModalProps> = ({ isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch<AppDispatch>();

  const onModalClose = () => {
    setReferralLink("");
    setError("");
    setIsModalOpen(false);
  };

  const isValidHex = (str: any) => /^[a-fA-F0-9]{24}$/.test(str);

  const [referralLink, setReferralLink] = useState("");
  const [error, setError] = useState("");

  const handleOk = async () => {
    try {
      if (!referralLink) {
        throw new Error("Referrer ID is missing from localStorage");
      }
      if (!isValidHex(referralLink)) {
        setError("id must be a 24-character hexadecimal string");
        return;
      }
      await dispatch(submitReferral(referralLink)).unwrap();
      setIsModalOpen(false);
      notifySuccess("Successfully Referred");
      setReferralLink("");
      setError("");
    } catch (error) {
      console.error("Error sending referral:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setReferralLink(value);

    if (value && !isValidHex(value)) {
      setError("id must be a 24-character hexadecimal string");
    } else {
      setError("");
    }
  };

  return (
    <Modal open={isModalOpen} footer={null} onCancel={onModalClose} closable={false} width={800}>
      <div className="modal_body_task">
        <div className="modal_header">
          <div className="modal_title">Make a Referral</div>
          <div className="modal_close">
            <CloseOutlined className="close_icon" onClick={onModalClose} style={{ fontSize: 14 }} />
          </div>
        </div>
        <div style={{ margin: "20px 0px" }}>
          <Input
            placeholder="Paste your referral link here"
            value={referralLink}
            onChange={handleInputChange} // Update here
            required
            style={{ background: "#f5eee7", height: 50, fontSize: 16, fontWeight: 400, fontFamily: "Founders Grotesk" }}
          />
          {error && (
            <div style={{ color: "red", fontSize: 14, fontWeight: 300, fontFamily: "Founders Grotesk" }}>{error}</div>
          )}{" "}
          {/* Display error here */}
        </div>
        <div style={{ display: "flex", width: "100%", margin: "20px 0px", gap: 10 }}>
          <Button
            style={{ flex: 1, backgroundColor: "#c5705d", color: "#fff", border: "none", height: 40 }}
            onClick={handleOk}
          >
            Make a Referral
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReferralModal;
