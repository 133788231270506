import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  isLogin: boolean;
  loading: boolean;
  discordLink: string | null; 
  error: string | null;    
  referralLoading: boolean; // New loading state for referrals
  referralError: string | null; // New error state for referrals
  userId: string | null;
  
}


const initialState: AuthState = {
  isLogin: false,
  loading: false,
  discordLink: null,
  error: null,
  referralLoading: false,
  referralError: null,
  userId:null

};


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLogin(state, action: PayloadAction<boolean>) {
      state.isLogin = action.payload;
    },
    connectDiscordStart(state) {
      state.loading = true;  
      state.error = null;    
    },
    connectDiscordSuccess(state, action: PayloadAction<string>) {
      state.loading = false; 
      state.discordLink = action.payload;
    },
    connectDiscordFailure(state, action: PayloadAction<string>) {
      state.loading = false; 
      state.error = action.payload; 
    },
    resetDiscordLink(state) {
      state.discordLink = null; 
    },
    resetReferralState(state) {
      state.referralLoading = false;
      state.referralError = null;
    },

    submitReferralStart(state) {
      state.referralLoading = true;
      state.referralError = null;
    },
    submitReferralSuccess(state) {
      state.referralLoading = false;
    },
    submitReferralFailure(state, action: PayloadAction<string>) {
      state.referralLoading = false;
      state.referralError = action.payload;
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;

    },


  },
});

export const {
  setIsLogin,
  connectDiscordStart,
  connectDiscordSuccess,
  connectDiscordFailure,
  resetDiscordLink,
  resetReferralState,
  submitReferralStart,
  submitReferralSuccess,
  submitReferralFailure,
  setUserId


} = authSlice.actions;

export default authSlice.reducer;

