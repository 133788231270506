import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "interceptor/axiosInstance";
import { notifyError } from "uiComponents/Toast";
import {
  leaderBoardDataFailure,
  leaderBoardDataLoading,
  leaderBoardDataSuccess,
  scoreDataFailure,
  scoreDataLoading,
  scoreDataSuccess
} from "./reducer";

export const _getLeaderBoardData = createAsyncThunk("profile/leaderboard", async (_, { dispatch }) => {
  dispatch(leaderBoardDataLoading());

  try {
    const response = await axiosInstance.get(`users/leaderboard`);
    console.log("WORTHY", response)
    dispatch(leaderBoardDataSuccess(response?.data?.data));
  } catch (error: any) {
    notifyError("Error");
    dispatch(leaderBoardDataFailure(error.message || "Error submitting referral"));
    throw error;
  }
});

export const _getScoreData = createAsyncThunk("profile/score", async (_, { dispatch }) => {
  dispatch(scoreDataLoading());

  try {
    const response = await axiosInstance.get(`users/score`);
    console.log("JJPJHH", response?.data?.data)
    dispatch(scoreDataSuccess(response?.data?.data));
  } catch (error: any) {
    notifyError("Error");
    dispatch(scoreDataFailure(error.message || "Error submitting referral"));
    throw error;
  }
});
