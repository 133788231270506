import React from "react";
import { Progress } from "antd";
import "antd/dist/reset.css"; // Import Ant Design's CSS

interface CustomProgressBarProps {
  tradersPortotalMScore?: number; // or whatever type you expect
  totalAchievedMScore?: number; // or whatever type you expect
}

const CustomProgressBar: React.FC<CustomProgressBarProps> = ({ tradersPortotalMScore, totalAchievedMScore }) => {
  console.log("KKKK", totalAchievedMScore, tradersPortotalMScore);
  const percentage = totalAchievedMScore && tradersPortotalMScore && (100 / tradersPortotalMScore) * 100;
  // Define the custom colors
  const trackColor = "#D0B8A8"; // Track color
  const fillColor = "#C5705D"; // Fill color

  // Inline styles for the progress bar container
  const containerStyle: React.CSSProperties = {};

  // Inline styles for the custom progress bar
  const progressStyle: React.CSSProperties = {
    width: "100%",
    height: "8px" // Adjust height as needed
  };

  return (
    <div style={containerStyle}>
      <Progress
        percent={percentage}
        showInfo={false}
        strokeColor={fillColor}
        trailColor={trackColor}
        strokeWidth={15} // Adjust stroke width if needed
        style={progressStyle} // Apply inline styles
      />
    </div>
  );
};

export default CustomProgressBar;
