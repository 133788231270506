import { useEffect, useState } from "react";
import { Buffer } from "buffer";
import { Layout, ConfigProvider, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CustomHeader } from "layout";
import HeroSection from "components/HeroSection";
import ProgressOverview from "components/ProgressOverview";

import ReferralSection from "components/Referral";
import GetMento from "assets/svg/SquidImage.svg";
import "styles/App.css";
import { useWeb3React } from "@web3-react/core";
import { usePersonalSign } from "hooks/usePersonalSign";
import { _loginWithWallet } from "state/auth/actions";
import { AppDispatch, RootState } from "store/store";
import { _getLeaderBoardData, _getScoreData } from "state/profile/actions";
import MentoQuest from "components/MentoQuest";
import LeaderBoard from "components/LeaderBoard";
import ReferralModal from "uiComponents/Modal/referralModal";

function App() {
  if (!window.Buffer) window.Buffer = Buffer;

  const { isLogin } = useSelector((state: RootState) => state.auth);
  const isColor = useSelector((state: any) => state.profile);
  const dispatch = useDispatch<AppDispatch>();
  const { chainId, isActive, account } = useWeb3React();
  const { personalSign } = usePersonalSign();

  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  console.log("ll", modalVisible);

  useEffect(() => {
    const duration = 3000;
    const intervalTime = 60;
    const totalSteps = duration / intervalTime;
    let currentStep = 0;

    const interval = setInterval(() => {
      currentStep += 1;
      const newProgress = Math.min(100, (currentStep / totalSteps) * 100);
      setProgress(newProgress);

      if (newProgress >= 100) {
        setLoading(false);
        clearInterval(interval);
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const signMessage = async () => {
      // if (isActive) {
      try {
        const { success, data } = await personalSign("Example `personal_sign` message");
        if (success) {
          await dispatch(
            _loginWithWallet({
              account: account ? account : "",
              chainId: chainId ? chainId?.toString() : "",
              signedMessage: data,
              setModalVisible
            })
          ).unwrap();
        }
      } catch (error) {
        console.error("Error signing message or calling API:", error);
      }
      // }
    };

    signMessage();
  }, [personalSign, account, chainId, isActive]);

  useEffect(() => {
    const saveCodeToLocalStorage = () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      if (code) {
        localStorage.setItem("discordCode", code);
      }
    };

    saveCodeToLocalStorage();
  }, []);

  useEffect(() => {
    if (isLogin && account) {
      dispatch(_getLeaderBoardData());
      dispatch(_getScoreData());
    }
  }, [isLogin, account]);

  return (
    <ConfigProvider>
      <Layout className="layout">
        {loading && (
          <div className="overlay_container_loader">
            <Progress type="circle" percent={progress} strokeColor={"#C5705D"} />
          </div>
        )}

        <div className="container">
          <CustomHeader />
          <HeroSection />
          <ProgressOverview />

          <div className="getMento">
            <div className="getMentoText" style={{ color: isColor?.isColor }}>
              Get Mento
            </div>
            <img src={GetMento} alt="Get Mento" />
          </div>
        </div>

        <MentoQuest />
        <LeaderBoard />
        <div className="footer">
          <ReferralSection />
        </div>
      </Layout>
      <ReferralModal isModalOpen={modalVisible} setIsModalOpen={setModalVisible} />
    </ConfigProvider>
  );
}

export default App;
