import "./Footer.css";

import { ReactComponent as Mentos } from "assets/svg/Logo.svg";
import { ReactComponent as Discord } from "assets/svg/Discord.svg";
import { ReactComponent as Telegram } from "assets/svg/telegram.svg";
import { ReactComponent as Twitter } from "assets/svg/twitter.svg";
import Compass from "assets/images/Compass1.png";

const Footer = () => {
  const TWITTER = process.env.REACT_APP_TWITTER_LINK;
  const DISCORD = process.env.REACT_APP_DISCORD_LINK;
  const DOCS = process.env.REACT_APP_DOCS_LINK;
  const PRIVACYPOLICY = process.env.REACT_APP_PRIVACY_POLICY_LINK;

  return (
    <div className="parentSection">
      <div className="additionalSection">
        <div className="sectionDivLeft">
          <Mentos width={130} height={28} />
          <br />
          <br />
          <p className="descriptionText">
            As they navigated uncharted waters, the Voyagers collected M-Points, digital experience points that measured
            your progress and status.
          </p>
        </div>
        <div className="center_div">
          <div>
            {" "}
            <div className="connectNowContainer">
              <div className="connectNowTitle">Connect now</div>
              <div className="resourceItem">
                <a href={TWITTER} target="_blank" rel="noopener noreferrer" className="resourceItem">
                  <Twitter width={24} height={18} /> Twitter
                </a>
              </div>
              <div className="resourceItem">
                <a href={""} target="_blank" rel="noopener noreferrer" className="resourceItem">
                  <Telegram width={24} height={18} /> Telegram
                </a>
              </div>
              <div className="resourceItem">
                <a href={DISCORD} target="_blank" rel="noopener noreferrer" className="resourceItem">
                  <Discord width={24} height={18} /> Discord
                </a>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <div className="resourcesContainer">
              <div className="resourceTitle">Resources</div>
              <div className="resourceItem">Application</div>
              <div className="resourceItem">
                {" "}
                <a href={DOCS} target="_blank" rel="noopener noreferrer" className="resourceItem">
                  Documentation
                </a>
              </div>
              <div className="resourceItem">Systems</div>
              <div className="resourceItem">FAQs</div>
            </div>
          </div>
        </div>

        <div className="compassImage">{<img src={Compass} className="compass" alt="Compass" />} </div>
      </div>
      <div className="footerLinks">
        <div className="copyright">All copyrights reserved &copy;</div>
        <div className="gridFooter">
          <div className="copyright">Terms and Conditions</div>
          <div className="copyright">
            <a href={PRIVACYPOLICY} target="_blank" rel="noopener noreferrer" className="copyright">
              Privacy Policy
            </a>
          </div>
        </div>

        <div className="copyright"> </div>
      </div>
    </div>
  );
};

export default Footer;
