import React, { useEffect, useState } from "react";
import HeroShip from "assets/images/1.webp";
import HeroShip1 from "assets/images/2.webp";
import HeroShip2 from "assets/images/3.webp";
import MobileHero from "assets/images/61.png";
import MobileHero1 from "assets/images/62.png";
import MobileHero2 from "assets/images/63.png";

import "./HeroSection.css";
import { DisplayPaneProps } from "types";
import { useWindowSize } from "hooks";

const HeroSection: React.FC<DisplayPaneProps> = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const { isMobile } = useWindowSize();

  const images = [
    isMobile ? MobileHero : HeroShip,
    isMobile ? MobileHero1 : HeroShip1,
    isMobile ? MobileHero2 : HeroShip2
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="hero-section">
      <img src={images[currentImageIndex]} alt="Hero Ship" className="hero-ship" height={235} />
    </div>
  );
};

export default HeroSection;
