import { useCallback, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

export const usePersonalSign = () => {
  const { provider, account } = useWeb3React();
  const [loading, setLoading] = useState<boolean>(false);

  const personalSign = useCallback(
    async (message: string): Promise<{ success: boolean; data: string }> => {
      setLoading(true);
      try {
        if (!provider) {
          throw new Error("Provider is not available");
        }
        if (!account) {
          throw new Error("Account is not connected");
        }

        // Assuming provider is connected to MetaMask or similar wallet
        const result = await provider.send("personal_sign", [
          ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
          account
        ]);
        return { success: true, data: result };
      } catch (error: any) {
        const message = error.reason ?? error.message ?? error;
        return { success: false, data: message };
      } finally {
        setLoading(false);
      }
    },
    [provider, account]
  );

  return {
    loading,
    personalSign
  };
};
