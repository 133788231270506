import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'interceptor/axiosInstance';
import { connectDiscordSuccess, connectDiscordFailure, setIsLogin, submitReferralStart, submitReferralSuccess, submitReferralFailure, setUserId } from './reducer';
import { notifyError } from 'uiComponents/Toast';
import { _getScoreData } from 'state/profile/actions';


export const _loginWithWallet = createAsyncThunk(
    'auth/loginWithWallet',
    async ({ account, chainId, signedMessage,setModalVisible }: { account: string; chainId: string; signedMessage: string,setModalVisible:any }, { dispatch }) => {
      const response = await axiosInstance.post("auth/login/wallet", {
        walletAddress: account,
        chainId: chainId?.toString(),
        signedMessage
      });
      console.log("KKK", response)
  
      dispatch(setIsLogin(true));
      localStorage.setItem("token", response.data.data.access_token);
      localStorage.setItem("id", response.data.data.id);
      setModalVisible( response.data.data.newUser)
      dispatch(setUserId(response.data.data.id))
      return response.data; 
    }
  );
  
export const connectDiscord = createAsyncThunk(
  'auth/connectDiscord',
  async (_, { dispatch }) => {
    try {
      const response = await axiosInstance.get("discord/auth");
      const discordLink = response.data?.data?.url;
      if (discordLink) {
        window.open(discordLink, "_blank"); 
        dispatch(connectDiscordSuccess(discordLink));
      }
      console.log("Discord connection successful:", response.data);
    } catch (error) {
      notifyError('Error')
      dispatch(connectDiscordFailure("Failed to connect to Discord")); 
      throw error; 
    }
  }
);


export const submitReferral = createAsyncThunk(
    'auth/submitReferral',
    async (referralLink: string, { dispatch }) => {
      dispatch(submitReferralStart());
      try { 
       const response =  await axiosInstance.post("users/referral", { referrer: referralLink });
       console.log("JJJ", response)
        dispatch(submitReferralSuccess());
        dispatch(_getScoreData());
      } catch (error: any) {
        console.log("EEE", error)
        notifyError(error?.response?.data?.message)
        dispatch(submitReferralFailure(error.message || "Error submitting referral"));
        throw error; 
      }
    }
  );
  