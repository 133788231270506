import { useState } from "react";
import { Layout } from "antd";
import ConnectAccount from "components/Account/ConnectAccount";
import { ReactComponent as Mentos } from "assets/svg/Logo.svg";
import CommonModal from "uiComponents/Modal";
import "./CustomHeader.css";
import { useWindowSize } from "hooks";
import CasinoCounter from "components/CasinoCounter";

const { Header } = Layout;

const CustomHeader = () => {
  const [taskModal, setTaskModal] = useState<boolean>(false);
  const { isMobile, isAmbigous } = useWindowSize();

  return (
    <Header className="header_custom">
      <div className="header__left">
        <Mentos className="mentos_icon" />
      </div>
      {isMobile || isAmbigous ? (
        <div className="header__right">
          <ConnectAccount />
        </div>
      ) : (
        <div className="header__right">
          <CasinoCounter />
          <ConnectAccount />
        </div>
      )}
      <CommonModal isModalOpen={taskModal} setIsModalOpen={setTaskModal} />
    </Header>
  );
};

export default CustomHeader;
