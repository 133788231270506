import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LeaderBoardData, ScoreData } from "types";

interface ProfileState {
  isColor: string;
  multiplier: number;

  leaderBoardData: LeaderBoardData[];
  leaderBoardDataLoading: boolean;
  leaderBoardDataError: null | string;

  currentSeason: number;

  scoreData:ScoreData | null;
  scoreDataLoading: boolean;
  scoreDataError:null|string;

}

const initialState: ProfileState = {
  isColor: "#47383B",
  multiplier: 1,

  leaderBoardData: [],
  leaderBoardDataLoading: false,
  leaderBoardDataError: null,

  currentSeason:1,

  scoreData:null,
  scoreDataLoading: false,
  scoreDataError:null
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setIsMentoColor(state, action: PayloadAction<string>) {
      state.isColor = action.payload;
    },
    setMultiplier(state, action: PayloadAction<number>) {
      state.multiplier = action.payload;
    },

    leaderBoardDataLoading(state) {
      state.leaderBoardDataLoading = true;
      state.leaderBoardDataError = null;
    },
    leaderBoardDataSuccess(state, action: any) {
      state.leaderBoardDataLoading = false;
      state.leaderBoardData = action.payload?.leaderboard;
      state.currentSeason = action?.payload?.season;
    },
    leaderBoardDataFailure(state, action: PayloadAction<string>) {
      state.leaderBoardDataLoading = false;
      state.leaderBoardDataError = action.payload;
    },

    scoreDataLoading(state) {
      state.scoreDataLoading = true;
      state.scoreDataError = null;
    },
    scoreDataSuccess(state, action: any) {
      state.scoreDataLoading = false;
      state.scoreData = action.payload;
    },
    scoreDataFailure(state, action: PayloadAction<string>) {
      state.scoreDataLoading = false;
      state.scoreDataError = action.payload;
    }
  }
});

// Export the action creators and the reducer
export const { setIsMentoColor, setMultiplier, leaderBoardDataLoading, leaderBoardDataSuccess, leaderBoardDataFailure,scoreDataLoading,scoreDataSuccess,scoreDataFailure } = profileSlice.actions;
export default profileSlice.reducer;
