import CountUp from "react-countup";
import { DisplayPaneProps } from "types";
import "./index.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const CasinoCounter: React.FC<DisplayPaneProps> = () => {
  const [startCount, setStartCount] = useState(false);
  const { isLogin } = useSelector((state: RootState) => state.auth);
  const { scoreData } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartCount(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // Convert mScore to an array of digits
  const scoreDigits = isLogin && scoreData?.mScore ? scoreData.mScore.toString().split("") : ["0", "0", "0"];

  return (
    <div className="points_container_parent">
      <div className="points_container_child">
        <div className="my_points_heading">My Points</div>
        <div className="my_points_content">
          {startCount && isLogin && (
            <>
              {scoreDigits.map((digit, index) => (
                <CountUp key={index} className="my_points_digit" start={0} end={parseInt(digit, 10)} duration={5} />
              ))}
            </>
          )}
          {!isLogin && (
            <div className="my_points_content">
              <div className="my_points_digit">0</div>
              <div className="my_points_digit">0</div>
              <div className="my_points_digit">0</div>
            </div>
          )}
        </div>
      </div>
      <div className="multiplier_parent">
        <div className="my_points_heading">My Multiplier</div>
        <div className="multiplier_content">
          <div className="multiplier_heading">Mento Scholar</div>
          <div className="multiplier_number"> {isLogin ? scoreData?.multiplier : "0"}x</div>
        </div>
      </div>
    </div>
  );
};

export default CasinoCounter;
