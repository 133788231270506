import { Button } from "antd";
import React, { useEffect, useState } from "react";
import CustomProgressBar from "uiComponents/ProgressBar";
import cardBackground from "assets/svg/SpBackground1.svg";
import cardBackground1 from "assets/svg/SpBackground2.svg";
import cardBackground2 from "assets/svg/SpBackground3.svg";

import { ReactComponent as MentoCoin } from "assets/svg/MentoCoin.svg";

import ImagePlaceholder from "assets/svg/PlaceHolderImage.svg";
import SocialPort from "assets/images/SocialsPort.png";
import TradersPort from "assets/images/TradersPort.png";
import GeneralPort from "assets/images/GeneralPort.png";

import "./index.css";
import { DisplayPaneProps, Styles } from "types";
import { ReactComponent as Discord } from "assets/svg/whiteDiscord.svg";
import { ReactComponent as DiscordFaded } from "assets/svg/fadedDiscod.svg";

import { useDispatch, useSelector } from "react-redux";
import { connectDiscord } from "state/auth/actions";
import { AppDispatch, RootState } from "store/store";
import CommonModal from "uiComponents/Modal";
import { useWeb3React } from "@web3-react/core";
import { _socialsPortTask, _tradersPortTask } from "state/quests/actions";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";
import ConnectAccount from "components/Account/ConnectAccount";

const styles: Styles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: "1200px",
    margin: "0px auto 0px",
    boxSizing: "border-box",
    textAlign: "center"
  },

  action: {
    display: "inline-flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px"
  },
  card: {
    padding: "20px",
    border: "2px solid #3C3633",
    borderRadius: "24px",
    backgroundColor: "transparent",
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    marginBottom: 32
  },
  cardParentDark: {
    padding: "20px",
    border: "2px solid #3C3633",
    borderRadius: "24px",
    marginBottom: 32,
    backgroundImage: `url(${cardBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  cardParentDark1: {
    padding: "20px",
    border: "2px solid #3C3633",
    borderRadius: "24px",
    marginBottom: 32,
    backgroundImage: `url(${cardBackground1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  cardParentDark2: {
    padding: "20px",
    border: "2px solid #3C3633",
    borderRadius: "24px",
    marginBottom: 32,
    backgroundImage: `url(${cardBackground2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },

  cardTitle: {
    fontWeight: 700,
    fontSize: "32px",
    color: "#3c3633"
  },

  cardScore: {
    color: "#3c3633",
    fontWeight: 500,
    fontSize: 16
  },

  cardDescription: {
    fontSize: 16,
    fontWeight: 400,
    color: "#8c8a8a"
  },
  cardDescriptionWhite: {
    fontFamily: "Founders Grotesk",
    fontSize: 16,
    fontWeight: 200,
    color: "#F8EDE3",
    lineHeight: 1
  },

  carouselButton: {
    padding: "20px 25px",
    fontFamily: "Founders Grotesk",
    fontSize: 14,
    fontWeight: "300",
    backgroundColor: "#3C3633",
    color: "#ffffff",
    border: "1px solid #4F4946",
    borderRadius: 12
  },
  carouselButtonMultiplier: {
    padding: "10px",
    fontFamily: "Founders Grotesk",
    fontSize: 14,
    fontWeight: "300",
    backgroundColor: "#3C3633",
    color: "#ffffff",
    border: "1px solid #4F4946",
    borderRadius: 12
  }
} as const;

const MentoQuest: React.FC<DisplayPaneProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [discordCode, setDiscordCode] = useState<boolean>(false);
  const { account } = useWeb3React();

  const { loading, discordLink, error, isLogin } = useSelector((state: RootState) => state.auth);
  const { tradersPortTask, tradersPortTaskLoading, socialsPortTask, socialsPortTaskLoading, socialsPortTaskError } =
    useSelector((state: RootState) => state.tasks);

  const { scoreData } = useSelector((state: RootState) => state.profile);

  const tradersPortotalMScore = tradersPortTask.reduce((accumulator: any, current: any) => {
    return accumulator + current.taskId.mScore;
  }, 0);

  const totalAchievedMScore = tradersPortTask.reduce((accumulator: any, current: any) => {
    return accumulator + current.mScore;
  }, 0);

  // const socialPorttotalMscore = socialsPortTask.reduce((acc, item: any) => {
  //   const rewardValue = item.rewards.reduce((sum: any, reward: any) => sum + reward.value, 0);
  //   return acc + rewardValue;
  // }, 0);

  const socialPorttotalMscore = socialsPortTask.reduce((sum: any, obj: any) => {
    if (Array.isArray(obj.rewards) && obj.rewards.length > 0) {
      const value = obj.rewards[0].value;
      return sum + (value > 0 ? value : 0);
    }
    return sum;
  }, 0);

  console.log("YEPPIE", socialPorttotalMscore);

  console.log("MSCORE", loading, discordLink, error, tradersPortotalMScore, totalAchievedMScore, socialPorttotalMscore);

  console.log("root", socialsPortTask, socialsPortTaskError, socialsPortTaskLoading);
  useEffect(() => {
    if (isLogin && account) {
      dispatch(_tradersPortTask(account));
    }
  }, [isLogin, account]);

  useEffect(() => {
    const _userId = localStorage.getItem("id");

    if (isLogin && account && _userId) {
      dispatch(_socialsPortTask(_userId));
    }
  }, [isLogin, account]);

  useEffect(() => {
    const code = localStorage.getItem("discordCode");
    if (code) {
      setDiscordCode(true);
    }
  }, []);

  const handleConnectDiscord = () => {
    dispatch(connectDiscord());
  };

  const onButtonClick = (task: any) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  return (
    <>
      <div style={styles.container} className="mento_container">
        <div className="quest_content">
          <div className="quest_heading" style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Mento Quest</div>
            {discordCode && isLogin ? (
              <div>
                <Button
                  type="primary"
                  onClick={handleConnectDiscord}
                  disabled={true}
                  style={{
                    fontFamily: "Fouders Grotesk",
                    position: "relative",
                    overflow: "hidden",
                    background: discordCode ? "#6FBF7D" : "inherit",
                    color: "#ffffff"
                  }}
                >
                  <div className="discord-button-content">
                    <Discord className="discord-icon" width={24} height={18} />
                    Discord Connected
                  </div>
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  type="primary"
                  onClick={handleConnectDiscord}
                  style={{ fontFamily: "Fouders Grotesk", position: "relative", overflow: "hidden" }}
                  disabled={!account || discordCode}
                >
                  <div className="discord-button-content">
                    {!account ? (
                      <DiscordFaded className="discord-icon" width={24} height={18} />
                    ) : (
                      <Discord className="discord-icon" width={24} height={18} />
                    )}
                    Connect Discord
                  </div>
                </Button>
              </div>
            )}
          </div>
          <div className="card_parent_dark">
            <div className="dark_card_quest">
              <div className="card_item image_cardSocial">
                <img src={SocialPort} width={140} height={140} style={{ borderRadius: "24px" }} />
              </div>
              <div className="card_item">
                <div className="parentMento">
                  <div className="cardTitleWhite">Socials Port</div>
                  <div className="card_item orange">
                    {0} / {socialPorttotalMscore}
                  </div>
                </div>

                <CustomProgressBar tradersPortotalMScore={socialPorttotalMscore} totalAchievedMScore={0} />
                <div style={{ ...styles.cardDescriptionWhite, lineHeight: 1.2 }} className="card_item white_card">
                  As they navigated unchartered waters, the voyagers collected M points, digital experience points that
                  measured your progress and status, earned through quests education and successful implementation of
                  local currency stable coins
                </div>
              </div>
            </div>
            {account ? (
              <div className="carousel">
                {socialsPortTaskLoading ? (
                  <Flex align="center" gap="middle">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: "#C5705D" }} spin />} />
                  </Flex>
                ) : (
                  <>
                    {socialsPortTask && socialsPortTask.length ? (
                      socialsPortTask.map((task: any, index: any) => (
                        <div key={index} className="carousel_child">
                          <div style={{ display: "flex" }} className="image_card">
                            <img src={ImagePlaceholder} width={261} height={143} className="image_rounded" />
                          </div>
                          {/* <div style={{ display: "flex", position: "relative" }} className="image_card">
                            {task.status !== "not_started" && task.status !== "fail" && (
                              <div style={{ position: "absolute", right: 0 }}>
                                {task.status === "pending" ? "In Progress" : "Completed"}
                              </div>
                            )}
                            <img src={ImagePlaceholder} width={261} height={143} className="image_rounded" />
                          </div> */}

                          <div>
                            <div className="save_finance_parent">
                              <div className="descpText" style={{ marginTop: 10 }}>
                                {task.name}
                              </div>
                            </div>
                            <div className="smallText">Complete {task?.tasks.length} tasks to earn</div>
                            <div className="button_center">
                              <Button className="button_responsive" onClick={() => onButtonClick(task)}>
                                <MentoCoin height={24} width={24} />+ {task?.rewards?.[0]?.value}
                              </Button>
                              <div className="carousal_button_multiplier">{scoreData?.multiplier}x</div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontFamily: "Founders Grotesk",
                              color: "#C5705D",
                              fontWeight: 500,
                              fontSize: 18
                            }}
                          >
                            No Tasks Available...
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
                <div>
                  {" "}
                  <div style={{ color: "#ffffff", fontFamily: "Founders Grotesk", margin: 10 }}>
                    Please Connect Your Wallet to See Tasks
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <ConnectAccount />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="card_parent_dark_1">
            <div className="dark_card_quest">
              <div className="card_item image_cardSocial" style={{ display: "flex" }}>
                <img src={TradersPort} width={140} height={140} style={{ borderRadius: "24px" }} />
              </div>
              <div className="card_item">
                <div className="parentMento">
                  <div className="cardTitleWhite">Traders Port</div>
                  <div className="card_item orange">
                    {totalAchievedMScore} / {tradersPortotalMScore}
                  </div>
                </div>

                <CustomProgressBar
                  tradersPortotalMScore={tradersPortotalMScore}
                  totalAchievedMScore={totalAchievedMScore}
                />
                <div style={{ ...styles.cardDescriptionWhite, lineHeight: 1.2 }} className="card_item white_card">
                  As they navigated unchartered waters, the voyagers collected M points, digital experience points that
                  measured your progress and status, earned through quests education and successful implementation of
                  local currency stable coins
                </div>
              </div>
            </div>
            {account ? (
              <div className="carousel">
                {tradersPortTaskLoading ? (
                  <Flex align="center" gap="middle">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: "#C5705D" }} spin />} />
                  </Flex>
                ) : (
                  <>
                    {" "}
                    {tradersPortTask && tradersPortTask.length ? (
                      tradersPortTask.map((task: any, index) => (
                        <div key={index} className="carousel_child">
                          <div style={{ display: "flex" }} className="image_card">
                            <img src={ImagePlaceholder} width={261} height={143} className="image_rounded" />
                          </div>
                          <div>
                            <div className="save_finance_parent">
                              <div
                                className="descpText"
                                style={{ marginTop: 10, textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                              >
                                {task?.taskId?.taskName}
                              </div>
                              <div style={{ lineHeight: 1 }} className="descpText marginDescp">
                                ({task?.taskId?.taskType})
                              </div>
                            </div>
                            <div className="smallText">Complete 1 task to earn</div>
                            <div className="button_center">
                              <Button className="button_responsive" onClick={() => onButtonClick(task)}>
                                <MentoCoin height={24} width={24} /> +{task?.taskId?.mScore}
                              </Button>
                              <div className="carousal_button_multiplier">{scoreData?.multiplier}x</div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <Flex align="center" gap="middle">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontFamily: "Founders Grotesk",
                              color: "#C5705D",
                              fontWeight: 500,
                              fontSize: 18
                            }}
                          >
                            No Tasks Available
                          </div>
                        </Flex>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
                <div>
                  {" "}
                  <div style={{ color: "#ffffff", fontFamily: "Founders Grotesk", margin: 10 }}>
                    Please Connect Your Wallet to See Tasks
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <ConnectAccount />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="card_parent_dark_2">
            <div className="dark_card_quest">
              <div className="card_item image_cardSocial" style={{ display: "flex" }}>
                <img src={GeneralPort} width={140} height={140} style={{ borderRadius: "24px" }} />
              </div>
              <div className="card_item">
                <div className="parentMento">
                  <div className="cardTitleWhite">General Port</div>
                  <div className="card_item orange">0 / 100</div>
                </div>

                <CustomProgressBar />
                <div style={{ ...styles.cardDescriptionWhite, lineHeight: 1.2 }} className="card_item white_card">
                  As they navigated unchartered waters, the voyagers collected M points, digital experience points that
                  measured your progress and status, earned through quests education and successful implementation of
                  local currency stable coins
                </div>
              </div>
            </div>
            <div className="carousel">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="carousel_child">
                  <div style={{ display: "flex" }} className="image_card">
                    <img src={ImagePlaceholder} width={261} height={143} className="image_rounded" />
                  </div>
                  <div>
                    <div className="save_finance_parent">
                      <div className="descpText" style={{ marginTop: 10 }}>
                        Save Finance
                      </div>
                      <div style={{ lineHeight: 1 }} className="descpText marginDescp">
                        (formerly Soled)
                      </div>
                    </div>
                    <div className="smallText">Complete 5 tasks to earn</div>
                    <div className="button_center">
                      <Button className="button_responsive" onClick={() => setIsModalOpen(true)}>
                        <MentoCoin height={24} width={24} /> +25 points
                      </Button>
                      <div className="carousal_button_multiplier">{scoreData?.multiplier}x</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        IsDiscordConnected={discordCode}
      />
    </>
  );
};

export default MentoQuest;
