import Money from "assets/images/MentoCoin2.png";
import Footer from "components/Footer";
import Lion from "assets/images/Lion1.png";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsMentoColor } from "state/profile/reducer";
import { ReactComponent as CopyIcon } from "assets/svg/CopyIcon.svg";
import { ReactComponent as InfoIcon } from "assets/svg/infoicon.svg";
import "./index.css";
import { Styles } from "types";
import { RootState } from "store/store";
// import { useWeb3React } from "@web3-react/core";
import ReferralModal from "uiComponents/Modal/referralModal";
import { useLocalStorageListener } from "hooks/useLocalStorageListener";

const styles: Styles = {
  sectionDiv: {
    flex: "1 1 0",
    padding: "10px",
    boxSizing: "border-box"
  },
  sectionDivPadding: {
    flex: "1 1 0",
    boxSizing: "border-box"
  },
  sectionDivLeft: {
    flex: "1 1 0",
    padding: "48px 0px 0px 48px",
    boxSizing: "border-box",
    textAlign: "left"
  },
  firstSection: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gap: "10px"
  },
  secondSection: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gap: "10px"
  },
  additionalSection: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr 1fr 1fr",
    gap: "10px",
    marginTop: "20px"
  },
  parentSection: {
    borderRadius: "24px",
    background: "#F5EEE7",
    marginBottom: 80
  },
  inputContainer: {
    position: "relative",
    width: "100%"
  },
  inputStyle: {
    width: "100%",
    padding: "0px 40px 0px 20px",
    height: 50,
    border: "1px solid #f8ede3",
    borderRadius: "12px",
    boxSizing: "border-box",
    background: "#F5EEE7"
  },
  copyIcon: {
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer"
  },
  buttonContainer: {
    display: "flex",
    gap: "10px",
    marginTop: "10px",
    width: "100%"
  },
  button: {
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    color: "white",
    fontSize: "14px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Founders Grotesk"
  },
  buttonOrange: {
    backgroundColor: "#C5705D",
    height: 50
  },
  buttonOutline: {
    backgroundColor: "#47383B",
    color: "#ffffff",
    height: 50
  }
} as const;

const ReferralSection = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { scoreData } = useSelector((state: RootState) => state.profile);
  const { userId: userIdClient, isLogin } = useSelector((state: RootState) => state.auth);
  // const { account } = useWeb3React();
  const id = useLocalStorageListener("id");

  const [userId, setUserId] = useState<any>("");
  const [isOpenReferralModal, setIsOpenReferralModal] = useState(false);
  const [showCopiedText, setShowCopiedText] = useState(false);
  const [isIconVisible, setIsIconVisible] = useState(true);

  useEffect(() => {
    console.log("id", id);
    if (userIdClient) {
      setUserId(userIdClient);
    } else {
      const id = localStorage.getItem("id");
      setUserId(id);
    }
  }, [userIdClient]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          dispatch(setIsMentoColor("#ffffff"));
        } else {
          dispatch(setIsMentoColor("#47383B"));
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const shareReferralLink = () => {
    const referralLink = `https://dev.mento.org/${userId}`;

    if (navigator.share) {
      navigator
        .share({
          title: "Join me on Mento",
          text: "Check out this referral link!",
          url: referralLink
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      // Fallback for unsupported browsers
      navigator.clipboard
        .writeText(referralLink)
        .then(() => {
          alert("Referral link copied to clipboard!");
        })
        .catch((error) => {
          console.error("Could not copy text: ", error);
        });
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`https://dev.mento.org/${userId}`);
    setIsIconVisible(false);
    setShowCopiedText(true);

    setTimeout(() => {
      setShowCopiedText(false);
      setIsIconVisible(true);
    }, 3000);
  };

  return (
    <div className="referral_container" ref={containerRef}>
      <div className="content">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="referral_header_container">Referral Overview</div>
          <div className="how_it_works">
            <InfoIcon width={16} height={16} />
            How it works
          </div>
        </div>

        <div className="action" style={{ width: "100%" }}>
          <div className="action_container">
            <div className="parentMoney">
              <div style={{ ...styles.sectionDivPadding, padding: 24 }}>
                <div className="referralHeading">Referrals Points</div>
                <div className="referralPoints"> {isLogin ? scoreData?.referralPoints : "0"}</div>
              </div>
              <div className="money_image_container">
                <img src={Money} className="money_image" />
              </div>
            </div>

            <div className="lion_container">
              <div style={{ ...styles.sectionDiv, padding: 24 }}>
                <div className="referralHeading">Referred Users</div>
                <div className="referralPoints"> {isLogin ? scoreData?.referredUser : "0"}</div>
              </div>
              <div className="lion_image_container">
                <img src={Lion} className="lion_image" />
              </div>
            </div>
          </div>
          <div className="referral_width">
            <div
              style={{ ...styles.sectionDiv, borderRadius: 24, height: 164, padding: "0px", width: "100%" }}
              className="referral_container_input"
            >
              <div style={{ ...styles.sectionDiv, padding: "0px" }}>
                <div className="referral_link">Your Referral Link</div>
                <div style={styles.inputContainer}>
                  <input
                    disabled={true}
                    type="text"
                    style={styles.inputStyle}
                    className="input-style"
                    placeholder={`https://dev.mento.org/${isLogin ? userId : ""}`}
                  />
                  {isIconVisible && <CopyIcon style={styles.copyIcon} onClick={handleCopyClick} />}
                  {showCopiedText && (
                    <span
                      className="copied_text"
                      style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)" }}
                    >
                      Copied!
                    </span>
                  )}
                </div>
                <div style={styles.buttonContainer}>
                  <button
                    disabled={!isLogin}
                    style={{ ...styles.button, ...styles.buttonOrange, borderRadius: 12, width: "100%" }}
                    onClick={() => setIsOpenReferralModal(true)}
                  >
                    Make a Referral
                  </button>
                  <button
                    disabled={!isLogin}
                    style={{ ...styles.button, ...styles.buttonOutline, borderRadius: 12, width: "100%" }}
                    onClick={shareReferralLink} // Updated onClick
                  >
                    Share
                  </button>
                </div>

                <div className="get_points">
                  <InfoIcon />
                  Get 100 points for each invited user
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ReferralModal isModalOpen={isOpenReferralModal} setIsModalOpen={setIsOpenReferralModal} />
    </div>
  );
};

export default ReferralSection;
