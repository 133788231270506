import React from "react";

import { Web3ReactProvider } from "@web3-react/core";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import connectors from "./connectors";
import { store } from "store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider connectors={connectors}>
        <ToastContainer />
        <App />
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>
);
