import React, { useEffect, useRef, useState } from "react";
import { useWindowSize } from "hooks";
import TreaureHunk from "assets/images/TreaureHunk.webp";
import Ship1 from "assets/images/ProgressShip.webp";
import Ship2 from "assets/images/HeavyShip.webp";
import Ship3 from "assets/images/HeaviestShip.webp";
import "./index.css";
import { Styles } from "types";
import CasinoCounter from "components/CasinoCounter";

const styles: Styles = {
  icon: {
    position: "absolute",
    transition: "left 0.5s ease, background-image 0.5s ease, transform 0.5s ease"
  }
};

const ProgressOverview: React.FC = () => {
  const steps: number[] = [0, 1, 2];
  const inactiveColors: string[] = ["#FFEFD9", "#D0B8A8", "#47383B"];
  const [progress, setProgress] = useState<number>(0);
  const [shipImage, setShipImage] = useState<string>(Ship1);
  const [scale, setScale] = useState<{ width: number; height: number; top: number }>({
    width: 52,
    height: 52,
    top: 50
  });
  const treasureRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isSailing, setIsSailing] = useState<boolean>(false);
  const { isMobile, isTablet, isAir4, isGalaxyTab } = useWindowSize();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startAnimation();
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const startAnimation = () => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= (isMobile ? 69 : isTablet ? 70 : isAir4 ? 70 : isGalaxyTab ? 72 : 80)) {
          clearInterval(interval);
          return isMobile ? 69 : isTablet ? 70 : isAir4 ? 70 : isGalaxyTab ? 72 : 80;
        }
        return Math.min(prev + 1, 100);
      });
    }, 200);
  };

  useEffect(() => {
    if (treasureRef.current) {
      treasureRef.current.style.left = `${progress}%`;
      setIsSailing(true);

      if (isMobile ? progress >= 0 && progress < 25 : progress >= 0 && progress < 30) {
        setShipImage(Ship1);
        setScale({ width: isMobile ? 38 : 52, height: isMobile ? 30 : 52, top: isMobile ? 15 : 50 });
      } else if (isMobile ? progress >= 25 && progress < 50 : progress >= 30 && progress < 60) {
        setShipImage(Ship2);
        setScale({ width: isMobile ? 45 : 100, height: isMobile ? 30 : 65, top: isMobile ? 10 : 35 });
      } else if (isMobile ? progress >= 50 : progress >= 60) {
        setShipImage(Ship3);
        setScale({ width: isMobile ? 50 : 110, height: isMobile ? 37 : 80, top: isMobile ? 5 : 25 });
      }
    }
  }, [progress]);

  return (
    <div ref={containerRef} className="progres_container">
      <div className="content">
        <div> {isMobile && <CasinoCounter />}</div>
        <div className="header_wrapper_progress">
          <div className="header_content">Progress Overview</div>
        </div>
        <div className="stepper">
          <div style={styles.progressContainer}>
            <div
              ref={treasureRef}
              className={isSailing ? "sailing" : ""}
              style={{
                ...styles.icon,
                width: scale.width,
                height: scale.height,
                backgroundImage: `url(${shipImage})`,
                backgroundSize: "cover",
                top: scale.top,
                zIndex: 1000
              }}
            />
            <img src={TreaureHunk} alt="Treasure" className="icon_treasure" />

            <div
              className="progress-overlay"
              //temporary fix will move it to better solution
              style={{
                width: `${isMobile ? (progress === 69 ? 90 : progress) : isTablet ? (progress === 70 ? 90 : progress) : isAir4 ? (progress === 70 ? 90 : progress) : isGalaxyTab ? (progress === 72 ? 90 : progress) : progress === 80 ? 90 : progress}%`
              }}
            />
          </div>

          {steps.map((step, index) => (
            <div key={index} className="step">
              {index === 0 && <div className="wide_marker" />}
              {index > 0 && <div className="marker" />}
              <div
                className={`step-line`}
                style={{
                  backgroundColor: inactiveColors[index]
                }}
              />
              <div className="step_Label" style={{ visibility: index === 0 ? "hidden" : "visible" }}>{`0${step}`}</div>
            </div>
          ))}
        </div>
        <hr className="hr_tag" />
      </div>
    </div>
  );
};

export default ProgressOverview;
